<template>
	<div class="lightbox" :class="{show}" @click="close">
		<div class="lightbox__bg"></div>
		<div class="lightbox__content" v-html="html"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			windowAspect: 1,
		}
	},
	props: ['show', 'type', 'value'],
	computed: {
		html: function () {
			if (!this.show) {
				return ''
			}

			if (this.type == 'html') {
				return this.value
			} else if (this.type == 'youtube') {
				const src = `//www.youtube.com/embed/${this.value}?${YOUTUBE_PARAM}`

				return this.generateIframeCode(src)
			} else if (this.type == 'vimeo') {
				const src = `//player.vimeo.com/video/${this.value}?${VIMEO_PARAM}`

				return this.generateIframeCode(src)
			} else if (this.type == 'image') {
				// Expects a DOM object <img />
				const img = this.value.cloneNode()
				img.setAttribute('id', '')
				img.setAttribute('class', '')
				return img.outerHTML
			} else {
				return ''
			}
		},
	},

	created() {
		const onResize = () => {
			this.windowAspect = window.innerHeight / window.innerWidth
		}

		window.addEventListener('resize', onResize)
		onResize()
	},

	methods: {
		close() {
			this.$emit('close')
		},

		generateIframeCode(src) {
			return `<iframe src="${src}" frameborder="0" allowfullscreen="0" webkitallowfullscreen="0" mozallowfullscreen="0"></iframe>`
		},
	},
}

//--------------------------------------------------

const VIMEO_PARAM = new URLSearchParams({
	title: 0,
	byline: 0,
	portrait: 0,
	color: 'ffffff',
	autoplay: 1,
}).toString()

const YOUTUBE_PARAM = new URLSearchParams({
	rel: 0,
	vq: 'hd720',
	rel: 0,
	hd: 1,
	hl: 'en',
	autoplay: 1,
	fs: 0,
	autohide: 1,
	theme: 'light',
	showinfo: 1,
	modestbranding: 1,
}).toString()
</script>
