<template>
	<div class="grid" ref="grid">
		<a
			class="grid-item"
			v-for="post in posts"
			:key="post.permalink"
			:href="post.permalink"
			:class="{highlighted: post.highlight}"
			:data-genres="post.genres"
		>
			<span v-html="post.thumb" />
			<hgroup class="grid-item__header">
				<h2 v-html="post.title" />
				<h3 v-if="post.tagline" v-html="post.tagline"></h3>
			</hgroup>
		</a>
	</div>
</template>

<script>
export default {
	data() {
		const postsData = document.getElementById('data-works').innerText
		const genresData = document.getElementById('data-genres').innerText

		const posts = JSON.parse(postsData)
		const genres = JSON.parse(genresData)

		return {
			posts,
			genres,
			filterGenre: '',
		}
	},

	// watch: {
	// 	filterGenre: function(genre) {
	// 		this.isotope.arrange({
	// 			filter(el) {
	// 				return el.dataset['genres'].search(genre) != -1
	// 			}
	// 		})
	// 	}
	// }
}
</script>
