import Vue from 'vue'
import clamp from 'clamp'

import './config'

import WorkList from './components/work-list.vue'
import Lightbox from './components/lightbox.vue'

const titleTransition = [
	'麦∿<i>B</i>aku',
	'麦∿<i>B</i>aku.',
	'麦∿<i>B</i>aku <i>H</i>',
	'ﾄ麦∿<i>B</i>aku <i>H</i>',
	'ﾄ麦∿<i>B</i>aku <i>H</i>m',
	'ﾄ麦∿<i>B</i>aku <i>H</i>mt',
	'林麦∿<i>B</i>aku <i>H</i>st',
	'林麦∿<i>B</i>aku <i>H</i>smt',
	'林麦∿<i>B</i>aku <i>H</i>simt',
	'林麦∿<i>B</i>aku <i>H</i>simot',
	'林麦∿<i>B</i>aku <i>H</i>simoto',
	'林麦∿<i>B</i>aku <i>H</i>asimoto',
	'ﾊﾓ麦∿<i>B</i>aku <i>H</i>asimoto',
	'ﾊ本麦∿<i>B</i>aku <i>H</i>asimoto',
	'ﾊ本麦∿<i>B</i>aku <i>H</i>ashimoto',
	'ﾊ本麦∿<i>B</i>aku <i>H</i>ashimoto',
	'橋本麦∿<i>B</i>aku <i>H</i>ashimoto',
	'橋本 麦∿<i>B</i>aku <i>H</i>ashimoto',
	'ｵ喬本　麦∿<i>B</i>aku <i>H</i>ashimoto',
	'木喬本　麦∿<i>B</i>aku <i>H</i>ashimoto',
	'木喬本　麦∿<i>B</i>aku <i>H</i>ash<i>i</i>moto',
	'木喬本　麦∿<i>B</i>aku <i>H</i>ash<i>i</i>mot<i>o</i>',
]

if (
	navigator.userAgent.match(/safari/i) &&
	!navigator.userAgent.match(/chrome/i)
) {
	document.body.classList.add('is-safari')
}

class App extends Vue {
	constructor() {
		super({
			el: '#app',
			data: {
				modal: null,
				lightbox: {
					type: null,
					value: '',
				},
			},
			computed: {
				hamburgerClass() {
					return {
						close: this.modal != null,
						inverted: this.modal == 'lightbox',
					}
				},

				gmenuClass() {
					return {
						show: this.modal == 'menu',
					}
				},
			},
			components: {
				WorkList,
				Lightbox,
			},
		})

		this.initContent()
		this.initTitle()
	}

	initContent() {
		const $content = document.querySelector('.content')

		// init video
		$content.querySelectorAll('.embed--lightbox').forEach((lb) => {
			lb.addEventListener('click', () => {
				const id = lb.dataset['id']
				const type = lb.dataset['type']

				this.lightbox.type = type
				this.lightbox.value = id
				this.modal = 'lightbox'
			})
		})

		// init outline
		const CurrentURI =
			location.protocol + '//' + location.host + location.pathname

		$content.querySelectorAll('.outline').forEach(($outline) => {
			let list = ''

			$content.querySelectorAll('h2, h3, h4').forEach((el) => {
				let type = el.tagName.toLowerCase()

				list += `
					<li class="outline__${type}">
						<a href="${CurrentURI}#${el.id}">
							${el.innerHTML}
						</a>
					</li>`
			})

			const menu = document.createElement('ul')
			menu.classList.add('outline__ul')
			menu.innerHTML = list

			$outline.querySelector('.outline__wrapper').append(menu)

			$outline
				.querySelector('.outline__button')
				.addEventListener('click', () => $outline.classList.toggle('expanded'))
		})

		// Initialize image
		$content.querySelectorAll('img:not(.light-disabled)').forEach((img) => {
			img.addEventListener('click', () => {
				this.lightbox.type = 'image'
				this.lightbox.value = img
				this.modal = 'lightbox'
			})
		})

		// Initialize highlight.js
		if ($content.querySelectorAll('.entry pre code').length > 0) {
			import('highlight.js').then((hljs) => {
				hljs.default.highlightAll()
			})
			import('highlight.js/styles/ascetic.css')
		}

		// Initialize glslCanvas
		// if ($content.querySelectorAll('.glslCanvas').length > 0) {
		// 	loadScript(
		// 		'https://rawgit.com/patriciogonzalezvivo/glslCanvas/master/build/GlslCanvas.js'
		// 	)
		// }

		// Fix the anchor link inside the content
		$content.querySelectorAll('a[href^="#"]').forEach((a) => {
			a.addEventListener('click', (e) => {
				e.preventDefault()
				const target = document.querySelector(a.getAttribute('href'))
				const offset = target.getBoundingClientRect().top + window.scrollY

				const headerOffset = document.querySelector('.gheader').offsetHeight

				window.scrollTo({
					top: offset - headerOffset,
				})
			})
		})
	}

	// vue methods
	toggleModal() {
		this.modal = this.modal ? null : 'menu'
	}

	initTitle() {
		const onScroll = () => {
			const y = window.scrollY
			const t = 1 - y / (window.innerHeight * 0.5)
			const i = Math.round(t * titleTransition.length)
			this.target = clamp(i, 0, titleTransition.length - 1)
		}

		onScroll()
		this.idx = this.target
		this.$refs.title.innerHTML = titleTransition[this.idx]

		window.addEventListener('scroll', onScroll)

		this.animateTitle()
	}

	animateTitle() {
		const el = this.$refs.title

		const cb = () => {
			if (this.idx === this.target) return

			const direction = Math.sign(this.target - this.idx)

			const newIdx = this.idx + direction

			if (newIdx < 0 || titleTransition.length <= newIdx) {
				return
			}

			this.idx = newIdx
			el.innerHTML = titleTransition[this.idx]
		}

		setInterval(cb, 20)
	}
}

new App()
